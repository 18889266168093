import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Alert,
  FloatingLabel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'
import logoBePass from '../../assets/images/bepass_logo_default.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateStatus } from '../components/gateStatus'
import { useForm } from 'react-hook-form'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../config/apiCall'
import { apiCompany } from '../components/ApiCall'
import { signInWithEmailAndPassword } from 'firebase/auth'

import { auth } from '../../config/firebase'

function Login() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const authgen = sessionStorage.getItem('auth')
  if (authgen === 'true') {
    window.location.href = '/control'
  }

  const [loginError, setLoginError] = useState({
    status: false,
    message: ''
  })

  const onSubmit = async (data: any) => {


    setLoginError({
      status: false,
      message: ''
    })


    try{

      let isThereAnRole = false
      const _temp_emailCheck = data.user.includes('@') ? data.user : `${data.user}@palmeiras.com.br`;
      const userCredential:any = await signInWithEmailAndPassword(auth, _temp_emailCheck, data.password);

      if(userCredential?.user?.accessToken) {
        sessionStorage.setItem('token', userCredential?.user?.accessToken)
        sessionStorage.setItem('user-key', JSON.stringify({user_key: userCredential?.user?.accessToken, tenant_id: 'palmeiras-pmxzp'}))
        const sessionItem:any = window.sessionStorage.getItem('user-key')
        const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}
        const getRolesAndToken: any = await apiCompany.get(`/firebase/user/roles/${userCredential.user.uid}`,
          {
            headers: {
              'x-api-key': user_key,
              'x-tenant-id': tenant_id,
              'x-api-version': 'v2'
            }
          }
        )

        if(getRolesAndToken?.data?.token) {

          console.log(getRolesAndToken.data)

          for(const item of getRolesAndToken.data.data.loginRoles){
            if(item.companyId === 'a4167a0e-9014-4e0a-bfb3-967401ca34ae'
            ){
              isThereAnRole = true
              sessionStorage.setItem('role', item.role.name)
            }
          }


          if(!isThereAnRole){
            setLoginError({
              status: true,
              message: 'Usuário ou senha inválidos'
            })
            return false
          }

          sessionStorage.setItem('name', userCredential?.user?.displayName || 'Verne')
          sessionStorage.setItem('user-key', JSON.stringify({user_key: getRolesAndToken?.data?.token.apiKey, tenant_id: 'palmeiras-pmxzp'}))
          sessionStorage.setItem('refreshToken', getRolesAndToken?.data?.refreshToken.token)

          sessionStorage.setItem('auth', 'true')
          sessionStorage.setItem('gate', '1')
          return (window.location.href = '/control')

        }
      }



      setLoginError({
        status: true,
        message: 'Usuário ou senha inválidos'
      })
      return false

    } catch (err: any) {
      setLoginError({
        status: true,
        message: 'Usuário ou senha inválidos'
      })
      return false
    }
  }


  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">

          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                <img src={logoBePass} className='login-logo' />
                <span className='logo-version'>v1.6</span>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Usuário"
                >
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    {...register('user', { required: true })}
                  />
                  {errors.user && (
                    <Alert variant="danger">Por favor, informe um e-mail</Alert>
                  )}
                </FloatingLabel>

                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Senha"
                >
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    {...register('password', { required: true })}
                  />
                  {errors.password && (
                    <Alert variant="danger">Por favor, informe uma senha</Alert>
                  )}
                </FloatingLabel>

                {/* <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="mb-2"
                >
                  <select
                    className="form-control"
                    {...register('gate', { required: true })}
                  >
                    <option value="Matarazzo">Matarazzo</option>
                    <option value="Palestra">Palestra</option>
                    <option value="Conveniencia">Conveniência</option>
                    <option value="Estacionamento">Estacionamento</option>
                    <option value="Operacional">Operacional</option>
                  </select>
                </Form.Group> */}

                {loginError.status && (
                  <Alert variant="danger">{loginError.message}</Alert>
                )}

                <Form.Group>
                  <Button
                    type="submit"
                    className="mb-0 mt-4 w-100 btn-success"
                    // onClick={() => clearErrors()}
                  >
                    Entrar
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
